import React from 'react';
import PropTypes from 'prop-types';
import LinkText from '../../atoms/LinkText/linkText';
import { ICONS } from '../../../constants/Icons';
import { MAX_LINKS_PER_COLUMN } from '../../../constants/Constants';
import './_linkList.less';

const LinkList = ({ links, modifier, dataCy }) => {
    if (!links) return null;

    const linkListClassName = `link-list
    ${modifier ? ` link-list--${modifier}` : ''}
    ${links.length > MAX_LINKS_PER_COLUMN ? ' link-list--two-columns' : ''}`;

    return (
        <ul className={linkListClassName}>
            {links.map((link, index) => (
                <li
                    key={`${link.title}${link.route}`}
                    className={`link-list__link ${
                        index % MAX_LINKS_PER_COLUMN === 0 // getting first elements in the columns
                            ? 'link-list__link--top-border-none'
                            : ''
                    }`}>
                    <LinkText
                        text={link.title}
                        route={link.route}
                        icon={link.icon ? ICONS[link.icon] : null}
                        dataCy={dataCy}
                    />
                </li>
            ))}
        </ul>
    );
};

LinkList.propTypes = {
    links: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    dataCy: PropTypes.string,
};

LinkList.defaultProps = {
    modifier: '',
    dataCy: '',
};

export default LinkList;
