import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TypeContext from './CardTypeContext';
import './_card.less';

const CardTitle = ({ title }) => {
    const type = useContext(TypeContext);
    const cardTitleClassName = `card__title card__title--${type}`;

    return (
        <h3 className={cardTitleClassName} data-cy='card__title'>
            {title}
        </h3>
    );
};

CardTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default CardTitle;
